import { Animation } from '@/Animation';
import './Stoppedgame.scss';
import { defineComponent } from 'vue';
import useMySchedule from '@/compositions/useMySchedule';
import useMyTeam from '@/compositions/useMyTeam';
import useRooms from '@/compositions/useRooms';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'Stoppedgame',
    setup() {
        const { team } = useMyTeam();
        return {
            ...useRooms(),
            ...useMySchedule('dekluis', team),
            ...useMyTeam(),
            ...useTranslations()
        };
    },
    data: () => ({
        subtitle: 'to start the game',
        stoppedTime: 0
    }),
    mounted() {
        Animation.animateRFIDScan();
        this.stoppedTime = Math.floor((new Date()).getTime() / 1000);
    }
});
